import request from '@/utils/request'

const baseUrl = 'https://api.teampoint.cn/amap/'
const resquestConfig = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  }
}
// api地址
const api = {
  addPolygon: baseUrl + 'v1/track/geofence/add/polygon',
  addCircle: baseUrl + 'v1/track/geofence/add/circle',
  deleteFence: baseUrl + 'v1/track/geofence/delete',
  getFence: baseUrl + 'v1/track/geofence/list',
  updateCircle: baseUrl + 'v1/track/geofence/update/circle',
  updatePolygon: baseUrl + 'v1/track/geofence/update/polygon'
}
export const addPolygonFence = (params) => {
  const data = {
    key: '504a5396f11273fc77c59dab29289c1e',
    sid: '569418'
  }
  Object.assign(data, params)
  return new Promise((resolve, reject) => {
    request.post(api.addPolygon, request.json2xForm(data), resquestConfig).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export const addCircleFence = (params) => {
  const data = {
    key: '504a5396f11273fc77c59dab29289c1e',
    sid: '569418'
  }
  Object.assign(data, params)
  return new Promise((resolve, reject) => {
    request.post(api.addCircle, request.json2xForm(data), resquestConfig).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export const deleteFence = (params) => {
  const data = {
    key: '504a5396f11273fc77c59dab29289c1e',
    sid: '569418'
  }
  Object.assign(data, params)
  return new Promise((resolve, reject) => {
    request.post(api.deleteFence, request.json2xForm(data), resquestConfig).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export const updateCircleFence = (params) => {
  const data = {
    key: '504a5396f11273fc77c59dab29289c1e',
    sid: '569418'
  }
  Object.assign(data, params)
  return new Promise((resolve, reject) => {
    request.post(api.updateCircle, request.json2xForm(data), resquestConfig).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export const updatePolygonFence = (params) => {
  const data = {
    key: '504a5396f11273fc77c59dab29289c1e',
    sid: '569418'
  }
  Object.assign(data, params)
  return new Promise((resolve, reject) => {
    request.post(api.updatePolygon, request.json2xForm(data), resquestConfig).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}
export const getAllFence = (gfids) => {
  const data = {
    key: '504a5396f11273fc77c59dab29289c1e',
    sid: '569418'
  }
  if (gfids) {
    return new Promise((resolve, reject) => {
      request.get(api.getFence + '?key=' + data.key + '&' + 'sid=' + data.sid + '&' + 'outputshape=1' + '&' + 'gfids=' + gfids).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  return new Promise((resolve, reject) => {
    request.get(api.getFence + '?key=' + data.key + '&' + 'sid=' + data.sid + '&' + 'outputshape=1').then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}
