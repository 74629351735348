<template>
  <div class="wrap" ref="wrap" @mouseup="mouseup">
    <div
      class="left-wrap"
      @mousemove="move"
      :style="`display:${isShowLeft ? 'block' : 'none'};border:${
        isShowLeft ? '' : 'none'
      }`"
    >
      <div class="top-wrap" ref="top-wrap">
        <!-- <div class="center-line" @mousedown="mousedown">
          <i class="iconfont icon-lashenshezhi"></i>
        </div> -->
        <div>
          <div class="title-wrap">
            <div class="box">
              <i class="iconfont icon-xiajiantou"></i>
              <span class="line-col"></span>
              <span class="title">厦门宇动能</span>
            </div>
          </div>
          <a-tree
            :treeData="treeData"
            show-icon
            default-expand-all
            v-model:selectedKeys="selectedKeys"
            @expand="expand"
          >
            <template #title="item">
              <div class="tree-item">
                <i class="iconfont icon-tongxunlu"></i>
                <span>{{ item.title }}</span>
                <span :title="'库存数量' + item.count">[{{ item.count }}]</span>
              </div>
            </template>
            <template #switcherIcon>
              <i class="iconfont icon-jianhao" v-if="isExpand"></i>
              <i class="iconfont icon-jiahao" v-else></i>
            </template>
          </a-tree>
        </div>
      </div>
      <div class="bottom-wrap" ref="bottom-wrap">
        <div class="title-wrap">
          <span :class="isFollow ? '' : 'span-active'" @click="isFollow = false"
            >账户设备(56)</span
          >
          <span :class="isFollow ? 'span-active' : ''" @click="isFollow = true"
            >我的关注(0)</span
          >
        </div>
        <div v-if="!isFollow">
          <battery-item
            ref="batteryItem"
            @infoWindow="infoWindow"
          ></battery-item>
        </div>
        <div v-else>2</div>
      </div>
    </div>
    <div class="right-wrap" id="reaction-map">
      <div class="show-left-wrap">
        <i
          class="iconfont icon-zuojiantou"
          v-if="isShowLeft"
          @click="toggleLeftInfo"
        ></i>
        <i class="iconfont icon-youjiantou" v-else @click="toggleLeftInfo"></i>
      </div>
      <div class="battery-address-info">
        <div
          v-if="windowInfo.deviceId"
          class="info-left"
          :title="windowInfo.deviceId + ':' + fullAddress"
        >
          {{ windowInfo.deviceId }}:{{ fullAddress }}
        </div>
        <div class="info-right">
          <div>
            <a-select
              default-value="高德地图"
              style="width: 120px"
              @change="handleChange"
            >
              <a-select-option value="高德地图"> 高德地图 </a-select-option>
            </a-select>
          </div>
          <span style="color: blue; margin: 0 10px" @click="setZoomAndCenter"
            >全景</span
          >
          <div class="marker-select-box">
            <span style="margin: 0 10px"
              >显示POI<i
                class="iconfont icon-xiajiantou"
                style="color: grey"
              ></i
            ></span>
            <div class="marker-select">
              <a-checkbox-group
                v-model:value="markerList"
                @change="onMarkerChange"
              >
                <a-row>
                  <a-col>
                    <a-checkbox value="battery">电池</a-checkbox>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <a-checkbox value="charge">换电柜</a-checkbox>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col>
                    <a-checkbox value="point">租赁点</a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </div>
          </div>
          <div>
            <a-checkbox-group
              v-model="checkedList"
              :options="plainOptions"
              @change="onCheckboxChange"
            >
            </a-checkbox-group>
          </div>
        </div>
      </div>
      <div class="reload-box">
        <span>{{ refreshTime }}</span
        >秒后刷新
      </div>
      <div class="speed-place">
        <div class="speed">
          <div class="speed-item">
            <span>慢速</span>
            <span class="s-block" style="background-color: #336699"></span>
          </div>
          <div class="speed-item">
            <span>正常</span>
            <span class="s-block" style="background-color: #0f0"></span>
          </div>
          <div class="speed-item">
            <span>超速</span>
            <span class="s-block" style="background-color: #f50713"></span>
          </div>
          <div class="speed-item">
            <span>超速(1.5)</span>
            <span class="s-block" style="background-color: #950222"></span>
          </div>
        </div>
        <div class="place">
          <a-input-search
            placeholder="请输入地点查找"
            enter-button
            @search="onSearch"
            size="large"
          />
        </div>
      </div>
      <div class="setting-wrap">
        <div class="icon-wrap">
          <i class="iconfont icon-weilan" @click="ShowRailSetting"></i>
        </div>
        <div class="rail" v-if="isShowRailSetting">
          <div class="rail-set">
            <h4>设置围栏</h4>
            <div class="title">
              <span
                :class="isSettingRail ? 'span-active' : ''"
                @click="isSettingRail = true"
                >设置围栏</span
              >
              <span
                :class="isSettingRail ? '' : 'span-active'"
                @click="isSettingRail = false"
                >我的围栏</span
              >
            </div>
            <div v-if="isSettingRail">
              <a-form
                :model="railForm"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
              >
                <a-form-item label="名称">
                  <a-input v-model:value="railForm.name"></a-input>
                </a-form-item>
                <a-form-item label="围栏类型">
                  <a-select
                    v-model:value="railForm.type"
                    placeholder="请选择围栏类型"
                    @change="fenceTypeChange"
                  >
                    <a-select-option value="rectangle">矩形</a-select-option>
                    <a-select-option value="circle">圆形</a-select-option>
                    <a-select-option value="polygon">多边形</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="状态">
                  <a-select
                    v-model:value="railForm.state"
                    placeholder="请选择状态"
                  >
                    <a-select-option value="enabled">启用</a-select-option>
                    <a-select-option value="disable">停用</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="描述">
                  <a-input v-model:value="railForm.desc"></a-input>
                </a-form-item>
              </a-form>
              <div class="btn-wrap">
                <a-button
                  type="primary"
                  size="middle"
                  @click="saveFence"
                  v-if="!railForm.gfid"
                  >保存</a-button
                >
                <a-button
                  type="primary"
                  size="middle"
                  @click="updateFence"
                  v-else
                  >更新</a-button
                >
                <a-button
                  class="info-btn"
                  size="middle"
                  :disabled="railForm.gfid"
                  @click="drawFence(railForm.type)"
                  >新建围栏</a-button
                >
                <a-button
                  type="danger"
                  size="middle"
                  @click="clearFence"
                  :disabled="railForm.gfid"
                  style="background-color: red; color: #fff"
                  >清除</a-button
                >
              </div>
            </div>
            <div v-else>
              <div class="my-rail">
                <a-select
                  v-model:value="railType"
                  :size="size"
                  style="width: 120px"
                  :options="railOptions"
                ></a-select>
                <a-input-search
                  v-model:value="railsearchValue"
                  placeholder="请输入围栏搜索"
                  style="width: 200px"
                  @change="railSearchChange"
                  @search="onRailSearch"
                />
                <i class="iconfont icon-delete" @click="deleteFenceByGfid"></i>
              </div>
              <div>
                <a-table
                  row-key="gfid"
                  :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onSelectChange,
                    columnWidth: 20
                  }"
                  :columns="columns"
                  :data-source="dataSource"
                  :pagination="{ pageSize: 50 }"
                  :scroll="{ y: 100 }"
                >
                  <template #type="{ record }">
                    <div v-if="record.type == 'circle'">圆形</div>
                    <div v-if="record.type == 'rectangle'">矩形</div>
                    <div v-if="record.type == 'polygon'">多边形</div>
                  </template>
                  <template #action="{ record }">
                    <div style="color: blue" @click="updateFenceInfo(record)">
                      修改
                    </div>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info" style="display: none">
        <div ref="batteryInfo" class="info-box">
          <div @click="this.closeInfoWindow" class="closeIcon">X</div>
          <div>
            <span style="font-weight: bold">{{ windowInfo.deviceId }}</span>
            <span style="margin: 0 10px; font-weight: bold">G9</span>
            <i class="iconfont icon-phone-signal-full" style="color: #1b65b5" />
          </div>
          <div>
            <span class="text-desc">设备号(IMEI):</span
            ><span>{{ windowInfo.deviceId }}</span>
          </div>
          <div>
            <span class="text-desc">设备类型:</span
            ><span>{{ windowInfo.batteryType === '0' ? '三元' : '钛锂' }}</span>
          </div>
          <div>
            <span class="text-desc">设备状态:</span
            ><span>{{ windowInfo.speed }}km/h</span>
          </div>
          <div><span class="text-desc">设防状态:</span><span>未设防</span></div>
          <div>
            <span class="text-desc">电源电压:</span
            ><span>{{ windowInfo.voltageTotal }}V</span>
          </div>
          <div>
            <span class="text-desc">放电电流:</span
            ><span>{{ windowInfo.dPowerA }}A</span>
          </div>
          <div>
            <span class="text-desc">充电电流:</span
            ><span>{{ windowInfo.cPowerA }}A</span>
          </div>
          <div><span class="text-desc">ICCID:</span><span></span></div>
          <div>
            <span class="text-desc">通信:</span
            ><span>{{ windowInfo.heartTime }}</span>
          </div>
          <div>
            <span class="text-desc">定位:</span
            ><span>{{ windowInfo.gpsTime }}</span>
          </div>
          <div class="text-box"><span>高级</span> <span>BMS</span></div>
          <div class="text-box">
            <span>设备指令</span><span>多边形围栏</span><span>一键设防</span
            ><span>区域报警</span>
          </div>
          <div class="text-box">
            <span>街景</span><span>跟踪</span><span>回放</span><span>放大</span
            ><span>设备信息</span
            ><span>更多<i class="iconfont icon-xiajiantou" /></span>
          </div>
          <div class="amap-info-sharp"></div>
        </div>

        <div ref="cabinetInfo" class="info-box">
          <div @click="this.closeInfoWindow" class="closeIcon">X</div>
          <div style="margin-bottom: 8px">
            <!-- <span style="font-weight: bold">{{ windowInfo.deviceId }}</span>
            <span style="margin: 0 10px; font-weight: bold">G9</span> -->
            <!-- <i class="iconfont icon-phone-signal-full" style="color: #1b65b5" /> -->
          </div>
          <div>
            <span class="text-desc">换电柜名称:</span
            ><span>{{ cabinetInfo.cabinetName }}</span>
          </div>
          <div>
            <span class="text-desc">换电柜编号:</span
            ><span>{{ cabinetInfo.cabinetCode }}</span>
          </div>
          <div>
            <span class="text-desc">换电柜厂家:</span
            ><span>{{ cabinetInfo.brand }}</span>
          </div>
          <div>
            <span class="text-desc">换电柜型号:</span
            ><span>{{ cabinetInfo.model }}</span>
          </div>
          <div>
            <span class="text-desc">在柜电池数:</span
            ><span>{{ cabinetInfo.chargingCount }}</span>
          </div>
          <div>
            <span class="text-desc">换电柜地址:</span
            ><span>{{ cabinetInfo.address }}</span>
          </div>
          <div>
            <span class="text-desc">换电柜状态:</span
            ><span>{{
              cabinetInfo.state === 'using'
                ? '租赁中'
                : cabinetInfo.state === 'repair'
                ? '维修中'
                : cabinetInfo.state === 'stock'
                ? '库存'
                : ''
            }}</span>
          </div>
          <div>
            <span class="text-desc">在线状态:</span
            ><span>{{
              cabinetInfo.onlineState === '0'
                ? '离线'
                : cabinetInfo.onlineState === '1'
                ? '在线'
                : ''
            }}</span>
          </div>
          <div>
            <span class="text-desc">数据时间:</span
            ><span>{{ cabinetInfo.updateTime }}</span>
          </div>
          <div class="amap-info-sharp"></div>
        </div>

        <div ref="pointInfo" class="info-box">
          <div @click="this.closeInfoWindow" class="closeIcon">X</div>
          <div style="margin-bottom: 8px">
            <!-- <span style="font-weight: bold">{{ windowInfo.deviceId }}</span>
            <span style="margin: 0 10px; font-weight: bold">G9</span> -->
            <!-- <i class="iconfont icon-phone-signal-full" style="color: #1b65b5" /> -->
          </div>
          <div>
            <span class="text-desc">租赁点名称:</span
            ><span>{{ pointInfo.pointName }}</span>
          </div>
          <div>
            <span class="text-desc">租赁点地址:</span
            ><span>{{ pointInfo.address }}</span>
          </div>
          <div>
            <span class="text-desc">运营商:</span
            ><span>{{ pointInfo.operatorName }}</span>
          </div>
          <div class="amap-info-sharp"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'
import { ref } from 'vue'
import {
  addPolygonFence,
  addCircleFence,
  getAllFence,
  deleteFence,
  updateCircleFence,
  updatePolygonFence
} from '@/api/fence/fence.js'
import batteryItem from './battery-item.vue'
import * as batteryGpsLastApi from '@/api/rnt/batteryGpsLast.js'
import * as batteryBmsApi from '@/api/rnt/batteryBms.js'
import * as chargeCabinetApi from '@/api/rnt/chargeCabinet'
import * as pointApi from '@/api/rnt/point'
var map
var cluster
var mouseTool
var polyEditor
var polygon
var circle
var circleEditor
var batteryMarker = []
var polyFenceList
var circleFenceList
const infoWindow = new AMap.InfoWindow({
  offset: new AMap.Pixel(0, -30),
  isCustom: true
})
var chargeMarker = []
var pointMarker = []
var ownerMarker = []
var _renderClusterMarker = function (context) {
  var div = document.createElement('div')
  div.style.width = '32px'
  div.style.height = '32px'
  // div.style.backgroundColor = 'white'
  div.style.color = 'red'
  div.style.fontSize = '15px'
  div.style.fontWeight = '600'
  // div.style.borderRadius = '50%'
  div.innerHTML = context.count
  div.style.textAlign = 'center'
  div.style.lineHeight = '32px'
  div.style.backgroundImage = 'url("/static/cluster.png")'
  div.style.backgroundSize = 'contain'
  context.marker.setContent(div)
}
export default {
  components: {
    batteryItem
  },
  data() {
    return {
      refreshTime: 20,
      id: 0,
      windowInfo: {},
      cabinetInfo: {},
      pointInfo: {},
      polygonArr: [], // 更新围栏数组点
      fence: [], // 围栏数组
      pointArr: [], // 围栏坐标点,
      polyList: [],
      circleList: [],
      polyFenceList: [],
      circleFenceList: [],
      center: null,
      radius: null,
      cluster: null,
      drag: { ismove: false },
      isShowLeft: true,
      checkedList: [],
      markerList: ['battery'],
      plainOptions: ['显示围栏', '全屏地图', '显示设备名'],
      isExpand: true,
      treeData: [
        {
          title: '厦门宇动能',
          key: '0-0',
          count: 50,
          slots: {
            icon: 'icon',
            switcherIcon: 'switcherIcon'
          },
          children: [
            {
              title: '深圳源科能源A',
              key: '0-0-0',
              count: 50,
              slots: {
                icon: 'icon'
              }
            },
            {
              title: '福田云谷科技',
              key: '0-0-1',
              count: 50,
              slots: {
                icon: 'icon'
              }
            }
          ]
        }
      ],
      selectedKeys: [],
      railForm: {
        name: '',
        type: '',
        state: '',
        desc: ''
      },
      labelCol: {
        span: 4,
        offset: 3
      },
      wrapperCol: {
        span: 14
      },
      railOptions: [
        { value: '所有分类' },
        { value: '公司' },
        { value: '工厂' },
        { value: '口岸' }
      ],
      railType: ref(['所有分类']),
      size: ref('default'),
      railsearchValue: '', // 围栏搜索值
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          width: 48
        },
        {
          title: '描述',
          dataIndex: 'desc',
          width: 48
        },
        {
          title: '操作',
          dataIndex: 'op',
          width: 48,
          slots: {
            customRender: 'action'
          }
        }
      ],
      selectedRowKeys: [],
      dataSource: [],
      isSettingRail: true,
      isShowRailSetting: false,
      isFollow: false,
      timer: null,
      geocoder: null,
      fullAddress: ''
    }
  },
  watch: {
    fullAddress() {
      console.log('fullAddress改变了', this.fullAddress)
    }
  },
  async mounted() {
    await this.batteryGpsLastInfo()
    this.initMapData()
    await this.getAllFenceInfo()
    const that = this
    document.onmouseup = function () {
      that.drag.ismove = false
    }
    // this.refreshData()
  },
  methods: {
    // 将经纬度改为具体地址名称
    regeoCode(lnglat) {
      const that = this
      that.geocoder.getAddress(lnglat, function (status, result) {
        if (status === 'complete' && result.regeocode) {
          // that.addressList = result.regeocode.pois
          // console.log('res', result.regeocode)
          that.fullAddress = result.regeocode.formattedAddress
        } else {
          that.fullAddress = ''
          // console.log('获取地址失败')
        }
      })
    },
    refreshData() {
      if (!this.timer) {
        this.timer = setInterval(async () => {
          this.refreshTime--
          if (this.refreshTime === 0) {
            await this.resetMapData()
            clearInterval(this.timer)
            this.refreshTime = 20
            this.timer = null
            this.refreshData()
          }
        }, 1000)
      } else {
        clearInterval(this.timer)
      }
    },
    setZoomAndCenter() {
      map.setZoomAndCenter(4, [108.946609, 34.262324])
    },
    mapHideRail() {
      if (polyFenceList) {
        polyFenceList.map((item) => {
          map.remove(item)
        })
      }
      if (circleFenceList) {
        circleFenceList.map((item) => {
          map.remove(item)
        })
      }
    },
    // 将围栏数据显示到map地图上
    mapShowRail() {
      polyFenceList = []
      circleFenceList = []
      this.polyList.map((item) => {
        var path = []
        item.forEach((item2) => {
          var lnglat = new AMap.LngLat(item2[0], item2[1])
          path.push(lnglat)
        })
        var poly = new AMap.Polygon({
          path: path,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50
        })
        polyFenceList.push(poly)
      })

      map.add(polyFenceList)

      this.circleList.map((item) => {
        var circle = new AMap.Circle({
          center: item.center,
          radius: item.radius,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50
        })
        circleFenceList.push(circle)
      })
      map.add(circleFenceList)
    },
    // 围栏搜索按钮失去焦点触发
    railSearchChange() {
      this.getAllFenceInfo()
    },
    // 围栏数据处理方法
    strToList(str) {
      if (str) {
        const arr = str.split(';')
        const pathResult = []
        arr.forEach((item) => {
          pathResult.push(item.split(','))
        })
        return pathResult
      }
    },
    // 重绘围栏方法
    async drawRailFence(shape) {
      const that = this
      that.polygonArr = []
      if (shape.points) {
        const path = await that.strToList(shape.points)
        polygon = new AMap.Polygon({
          path: path,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50
        })
        map.add(polygon)
        map.setFitView([polygon])
        polyEditor = new AMap.PolyEditor(map, polygon)
        polyEditor.on('end', function (event) {
          console.log('end', event)
          const arr = event.target.De.path
          arr.forEach((item) => {
            that.polygonArr.push([String(item.lng), String(item.lat)])
          })
          // event.target 即为编辑后的多边形对象
        })
        polyEditor.open()
        return false
      }
      const center = shape.center.split(',')
      const radius = shape.radius
      circle = new AMap.Circle({
        center: new AMap.LngLat(Number(center[0]), Number(center[1])), // 圆心位置
        radius: radius, // 圆半径
        strokeColor: '#FF33FF',
        strokeWeight: 6,
        strokeOpacity: 0.3,
        fillOpacity: 0.4,
        fillColor: '#1791fc',
        zIndex: 50
      })
      map.add(circle)
      map.setFitView([circle])
      circleEditor = new AMap.CircleEditor(map, circle)
      circleEditor.on('end', function (event) {
        that.center = event.target.De.center
        that.radius = event.target.De.radius
        // event.target 即为编辑后的圆形对象
      })
      circleEditor.open()
    },
    // 更新围栏信息
    updateFenceInfo(record) {
      this.railForm = record
      this.isSettingRail = true
      this.pointArr = []
      if (polygon) {
        map.remove(polygon)
        polyEditor.close()
      }
      if (circle) {
        map.remove(circle)
        circleEditor.close()
      }
      const shape = record.shape
      this.drawRailFence(shape)
    },
    updateFence() {
      if (this.railForm.type === 'circle') {
        circleEditor.close()
        map.remove(circle)
        const center = this.center.lng + ',' + this.center.lat
        const radius = parseInt(this.radius)
        updateCircleFence(
          Object.assign(this.railForm, { center, radius })
        ).then((res) => {
          if (res.errcode !== 10000) {
            this.$message.error(res.errmsg)
            return
          }
          this.getAllFenceInfo()
          this.railForm = {}
          this.$message.success(res.errmsg)
        })
      }
      if (
        this.railForm.type === 'polygon' ||
        this.railForm.type === 'rectangle'
      ) {
        polyEditor.close()
        map.remove(polygon)
        const points = this.polygonArr.join(';')
        updatePolygonFence(Object.assign(this.railForm, { points })).then(
          (res) => {
            if (res.errcode !== 10000) {
              this.$message.error(res.errmsg)
              return
            }
            this.getAllFenceInfo()
            this.railForm = {}
            this.$message.success(res.errmsg)
          }
        )
      }
    },
    // 删除围栏By围栏id
    deleteFenceByGfid() {
      deleteFence({ gfids: this.selectedRowKeys.join(',') }).then((res) => {
        if (res.errcode !== 10000) {
          this.$message.error(res.errmsg)
          return
        }
        this.$message.success(res.errmsg)
        this.getAllFenceInfo()
      })
    },
    // 获取所有围栏信息
    async getAllFenceInfo() {
      const { data } = await getAllFence()
      if (data) {
        const results = data.results
        const railArr = []
        const polyArr = []
        const circleArr = []
        results.forEach((item) => {
          const railObj = {
            gfid: item.gfid,
            name: item.name,
            desc: item.desc,
            shape: item.shape
          }
          railArr.push(railObj)
          if (item.shape.points) {
            const result = this.strToList(item.shape.points)
            polyArr.push(result)
            return false
          }
          const circleObj = {
            center: item.shape.center.split(','),
            radius: item.shape.radius
          }
          circleArr.push(circleObj)
        })
        this.dataSource = railArr
        this.polyList = polyArr
        this.circleList = circleArr
      }
    },
    // 保存围栏信息
    saveFence() {
      if (
        this.railForm.type === 'polygon' ||
        this.railForm.type === 'rectangle'
      ) {
        const points = this.pointArr.join(';')
        addPolygonFence({
          name: this.railForm.name,
          points,
          desc: this.railForm.desc
        }).then((res) => {
          if (res.errcode !== 10000) {
            this.$message.error(res.errmsg)
            return
          }
          this.getAllFenceInfo()
          this.$message.success(res.errmsg)
        })
      }
      if (this.railForm.type === 'circle') {
        if (this.fence[0]) {
          const center =
            this.fence[0].De.center.lng + ',' + this.fence[0].De.center.lat
          const radius = parseInt(this.fence[0].De.radius)
          addCircleFence({
            name: this.railForm.name,
            center,
            radius,
            desc: this.railForm.desc
          }).then((res) => {
            if (res.errcode !== 10000) {
              this.$message.error(res.errmsg)
              return
            }
            this.getAllFenceInfo()
            this.$message.success(res.errmsg)
          })
        }
      }
      this.railForm = {}
      // this.isShowRailSetting = false
      mouseTool.close(true)
    },
    // 围栏类型改变监听
    fenceTypeChange() {
      mouseTool.close(true)
      this.fence = []
    },
    // 清除围栏方法
    clearFence() {
      mouseTool.close(true)
      this.railForm.type = ''
      this.fence = []
    },
    // 绘制围栏方法
    drawFence(type) {
      if (this.fence.length < 1) {
        if (type === 'rectangle') {
          mouseTool.rectangle()
        }
        if (type === 'circle') {
          mouseTool.circle()
        }
        if (type === 'polygon') {
          mouseTool.polygon()
        }
      } else {
        mouseTool.close()
      }
    },
    // 点标记显示改变监听
    onMarkerChange(e) {
      this.markerList = e
      this.resetMapData()
    },
    // 获取信息窗口数据
    async getInfoWindowData(id) {
      batteryBmsApi
        .page({ deviceId: id })
        .then((res) => {
          if (res.code === 0) {
            this.windowInfo = res.data[0]
            const lnglat =
              this.windowInfo.longitude + ',' + this.windowInfo.latitude
            this.regeoCode(lnglat)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 打开选中电池信息窗口
    async infoWindow(lnglat, id) {
      await this.getInfoWindowData(id)
      const sContent = this.$refs.batteryInfo
      infoWindow.setContent(sContent)
      infoWindow.open(map, lnglat)
    },
    // 显示点标记的label方法
    showMarkerLabel(markers) {
      markers.forEach((marker) => {
        const { extData } = marker.De
        marker.setLabel({
          content:
            '<div>' + extData.IMEI + '(' + extData.state + ')' + '</div>',
          offset: new AMap.Pixel(0, 0),
          direction: 'top'
        })
      })
    },
    // 隐藏点标记的label方法
    hiddenMarkerLabel(markers) {
      markers.forEach((marker) => {
        marker.setLabel({
          content: '',
          offset: new AMap.Pixel(0, 0),
          direction: 'top'
        })
      })
    },
    // 信息窗口打开
    async infoWindowOpen(e, name) {
      if (name === 'battery') {
        // console.log('e', e)
        await this.getInfoWindowData(e.target.De.extData.IMEI)
        const sContent = this.$refs.batteryInfo
        infoWindow.setContent(sContent)
        infoWindow.open(map, e.target.getPosition())
        this.$refs.batteryItem.setSelectItem(e.target.De.extData.IMEI)
      }
      if (name === 'cabinet') {
        const sContent = this.$refs.cabinetInfo
        infoWindow.setContent(sContent)
        infoWindow.open(map, e.target.getPosition())
      }
      if (name === 'point') {
        const sContent = this.$refs.pointInfo
        infoWindow.setContent(sContent)
        infoWindow.open(map, e.target.getPosition())
      }
    },
    // 关闭信息窗口
    closeInfoWindow() {
      infoWindow.close()
    },
    // 获取电池点位
    batteryGpsLastInfo() {
      return new Promise((resolve, reject) => {
        batteryGpsLastApi
          .all()
          .then((res) => {
            if (res.code === 0) {
              const resultArr = res.data.map((item) => {
                return {
                  IMEI: item.deviceId,
                  lanlgt: [item.longitude, item.latitude],
                  state: '在线'
                }
              })
              resolve(resultArr)
            }
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    // 后台获取换电柜经纬度
    getChargeCabinetData() {
      return new Promise((resolve, reject) => {
        chargeCabinetApi
          .all()
          .then((res) => {
            if (res.code === 0) {
              const resultArr = res.data.map((item) => {
                return {
                  lanlgt: [item.longitude, item.latitude],
                  data: item
                }
              })
              resolve(resultArr)
            }
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    // 后台获取门店经纬度
    getPointData() {
      return new Promise((resolve, reject) => {
        pointApi
          .all()
          .then((res) => {
            if (res.code === 0) {
              const resultArr = res.data.map((item) => {
                return {
                  lanlgt: [item.longitude, item.latitude],
                  data: item
                }
              })
              resolve(resultArr)
            }
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    // 获取地图点位数据
    async resetMapData(init) {
      const zoom = map.getZoom()
      // 后台获取电池点位
      // const bartterylnglats = [
      //   {
      //     lanlgt: [118.08923, 24.479406],
      //     IMEI: 'BM738945738475',
      //     state: '静止'
      //   },
      //   {
      //     lanlgt: [118.06923, 24.479406],
      //     IMEI: 'BM738945739975',
      //     state: '离线'
      //   },
      //   {
      //     lanlgt: [118.07923, 24.479406],
      //     IMEI: 'BM738945736775',
      //     state: '19km/h'
      //   }
      // ]

      const bartterylnglats = await this.batteryGpsLastInfo()
      const chargeCabinetlnglats = await this.getChargeCabinetData()
      const pointlnglats = await this.getPointData()
      batteryMarker = []
      bartterylnglats.forEach((item) => {
        const marker = new AMap.Marker({
          position: item.lanlgt,
          icon: this.buildIcon('batteryIcon', zoom, item.state),
          extData: {
            type: 'battery',
            IMEI: item.IMEI,
            state: item.state
          }
        })
        marker.on('click', (e) => {
          this.infoWindowOpen(e, 'battery')
        })
        batteryMarker.push(marker)
      })
      chargeMarker = []
      chargeCabinetlnglats.forEach((item) => {
        const marker = new AMap.Marker({
          position: item.lanlgt,
          icon: this.buildIcon('chargeCabinetIcon', zoom),
          extData: {
            type: 'charge'
          }
        })
        marker.on('click', (e) => {
          this.cabinetInfo = item.data
          this.infoWindowOpen(e, 'cabinet')
        })
        chargeMarker.push(marker)
      })
      pointMarker = []
      pointlnglats.forEach((item) => {
        const marker = new AMap.Marker({
          position: item.lanlgt,
          icon: this.buildIcon('pointIcon', zoom),
          extData: {
            type: 'point'
          }
        })
        marker.on('click', (e) => {
          this.pointInfo = item.data
          this.infoWindowOpen(e, 'point')
        })
        pointMarker.push(marker)
      })
      // ownerMarker = []
      // ownerMarker.forEach((item)=>{
      //   const marker = new AMap.Marker({
      //     position: item,
      //     icon: this.buildIcon('ownerIcon', zoom),
      //     extData: {
      //       type: 'owner'
      //     }
      //   })
      // })
      let clusterData = []
      if (this.markerList.indexOf('battery') !== -1) {
        clusterData = clusterData.concat(batteryMarker)
      }
      if (this.markerList.indexOf('charge') !== -1) {
        clusterData = clusterData.concat(chargeMarker)
      }
      if (this.markerList.indexOf('point') !== -1) {
        clusterData = clusterData.concat(pointMarker)
      }
      if (init === true) {
        return clusterData
      }
      this.resetCluster(clusterData)
    },
    // 重置点聚合方法
    resetCluster(data) {
      if (cluster) {
        cluster.setMap(null)
        cluster.setMap(map)
        cluster.setMarkers(data)
        return false
      }
      cluster = new AMap.MarkerClusterer(
        map, // 地图实例
        data, // 海量点组成的数组
        {
          gridSize: 30,
          renderClusterMarker: _renderClusterMarker,
          zoomOnClick: true,
          maxZoom: 17
        }
      )
    },
    // 创建icon图标
    buildIcon(name, zoom, state) {
      const sizeNum = zoom ? (Number(zoom) / 2) * 4 : 25
      if (name === 'batteryIcon') {
        // 电池图标
        if (state === '静止') {
          const batteryIcon = new AMap.Icon({
            size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
            image: '/static/battery.png', // Icon的图像
            imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
          })
          return batteryIcon
        }
        if (state === '离线') {
          const batteryIcon = new AMap.Icon({
            size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
            image: '/static/battery2.png', // Icon的图像
            imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
          })
          return batteryIcon
        }
        const batteryIcon = new AMap.Icon({
          size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
          image: '/static/battery3.png', // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
        })
        return batteryIcon
      } else if (name === 'chargeCabinetIcon') {
        // 换电柜图标
        const chargeCabinetIcon = new AMap.Icon({
          size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
          image: '/static/chargeCabinet.png', // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
        })
        return chargeCabinetIcon
      } else if (name === 'pointIcon') {
        // 租赁点图标
        const pointIcon = new AMap.Icon({
          size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
          image: '/static/point.png', // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
        })
        return pointIcon
      } else if (name === 'ownerIcon') {
        const ownerIcon = new AMap.Icon({
          size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
          image: '/static/owner.png', // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
        })
        return ownerIcon
      }
    },
    setOwnerMarker(lnglat) {
      // if (this.ownerMarker) {
      //   map.remove(this.ownerMarker)
      // }
      if (ownerMarker) {
        map.remove(ownerMarker)
      }
      // ownerMarker = []
      const startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(32, 32),
        // 图标的取图地址
        image: '/static/owner.png',
        imageOffset: new AMap.Pixel(0, 0),
        // 图标所用图片大小
        imageSize: new AMap.Size(32, 32)
      })
      ownerMarker = new AMap.Marker({
        icon: startIcon,
        position: lnglat,
        offset: new AMap.Pixel(-16, -40)
      })
      map.add(ownerMarker)

      // this.setForm(lnglat)
    },
    // 展示围栏信息设置
    ShowRailSetting() {
      this.isShowRailSetting = !this.isShowRailSetting
    },
    // 监听围栏表格选择改变
    onSelectChange(e) {
      this.selectedRowKeys = e
    },
    // 监听围栏搜索按钮
    onRailSearch(e) {
      this.dataSource.forEach((item) => {
        if (item.name === e) {
          getAllFence(item.gfid).then((res) => {
            if (res.errcode !== 10000) {
              return
            }
            this.dataSource = res.data.results
          })
        }
      })
    },
    // 监听树节点展开与折叠
    expand(e, data) {
      const { expanded } = data
      this.isExpand = expanded
    },
    // 监听多选框改变事件
    onCheckboxChange(list) {
      this.checkedList = list
      const index = list.indexOf('显示设备名')
      if (index === -1) {
        this.hiddenMarkerLabel(batteryMarker)
      } else {
        this.showMarkerLabel(batteryMarker)
      }
      const railIndex = list.indexOf('显示围栏')
      if (railIndex === -1) {
        this.mapHideRail()
      } else {
        this.mapHideRail()
        this.mapShowRail()
      }
    },
    // 处理下拉选择器改变监听
    handleChange() {},
    // 搜索按钮点击事件
    onSearch(e) {
      const that = this
      if (e) {
        this.geocoder.getLocation(e, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result中对应详细地理坐标信息
            console.log('result', result)
            that.setOwnerMarker(result.geocodes[0].location)
            map.setFitView(ownerMarker)
          }
        })
      } else {
        map.remove(ownerMarker)
      }

      // AMap.plugin(['AMap.Geocoder'],function(){

      // })
      // AMap.service(['AMap.PlaceSearch'], function () {
      //   const placeSearch = new AMap.PlaceSearch({
      //     pageIndex: 1
      //   })
      //   placeSearch.search(e, function (status, result) {
      //     console.log('result', result)
      //     const pois = result.poiList.pois
      //     if (pois) {
      //       const poi = pois[0]
      //       that.setOwnerMarker(poi.location)
      //       // that.addMarker(poi.location)
      //       map.setCenter(poi.location)
      //       // that.map.setFitView()
      //     }
      //   })
      // })
    },
    // 开关显示左边面板
    toggleLeftInfo() {
      this.isShowLeft = !this.isShowLeft
    },
    mousedown(E) {
      const ele = E.target
      const diffY = E.clientY
      const height = ele.offsetTop + ele.clientHeight
      const wrapHeight = this.$refs.wrap.clientHeight
      const minHeight = 200
      console.log('wrap', wrapHeight)
      this.drag = { ele, diffY, height, wrapHeight, minHeight }
      this.drag.ismove = true
    },
    mouseup(e) {
      this.drag.ismove = false
    },
    move(E) {
      if (!this.drag.ismove) {
        return
      }
      const height = this.drag.height + (E.clientY - this.drag.diffY)
      if (
        height > this.drag.minHeight &&
        this.drag.wrapHeight - this.drag.minHeight > height
      ) {
        this.$refs['top-wrap'].style.height = height + 'px'
      }
      this.$refs['bottom-wrap'].style.maxHeight =
        this.drag.wrapHeight - height + 'px'
    },
    stop(e) {
      this.drag.ismove = false
    },
    // 初始化地图数据
    async initMapData() {
      const that = this
      map = new AMap.Map('reaction-map', {
        center: [118.08923, 24.479406],
        resizeEnable: true,
        zoom: 10
      })
      AMap.plugin(
        ['AMap.ToolBar', 'AMap.Scale', 'AMap.PolyEditor', 'AMap.CircleEditor'],
        function () {
          map.addControl(new AMap.Scale())
          var tool = new AMap.ToolBar({
            offset: new AMap.Pixel(10, 55)
          })
          map.addControl(tool)
        }
      )
      const clusterData = await that.resetMapData(true)
      map.plugin(['AMap.MarkerClusterer'], function () {
        that.resetCluster(clusterData)
        cluster.on('click', () => {})
      })
      map.plugin(['AMap.Geocoder'], function () {
        that.geocoder = new AMap.Geocoder({
          city: '全国',
          extensions: 'all'
        })
      })
      map.plugin(['AMap.MouseTool'], function () {
        // 在地图中添加MouseTool插件
        mouseTool = new AMap.MouseTool(map)
        // 添加事件
        mouseTool.on('draw', (e) => {
          // 获取路径/范围
          that.pointArr = []
          if (that.fence.length < 1) {
            that.fence.push(e.obj)
            mouseTool.close()
          }
          const arr = e.obj.getPath()
          arr.forEach((item) => {
            that.pointArr.push([String(item.lng), String(item.lat)])
          })
          // console.log(that.fence.length)
          // console.log('fence', that.fence)
        })
      })
      map.on('zoomchange', this.mapZoom)
    },
    // 监听地图zoom 改变事件
    mapZoom(e) {
      this.closeInfoWindow()
    }
  }
}
</script>
<style lang='less' scoped>
.marker-select-box {
  position: relative;
  &:hover .marker-select {
    display: block;
  }
  .marker-select {
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    display: none;
  }
}
:deep(.amap-marker-label) {
  position: absolute;
  z-index: 2;
  border: 1px solid #fff;
  background-color: white;
  white-space: nowrap;
  cursor: default;
  padding: 5px;
  font-size: 12px;
  border-radius: 10px;
  line-height: 14px;
  font-weight: 450;
}
.info-box {
  background-color: #fff;
  width: 320px;
  padding: 10px 10px;
  border-radius: 10px;
  .closeIcon {
    position: absolute;
    right: 10px;
    top: 5px;
    font-weight: 500;
    color: grey;
    font-size: 17px;
  }
  .text-desc {
    font-weight: bold;
  }
  .text-box {
    display: flex;
    color: #1b65b5;
    font-weight: bold;
    cursor: pointer;
    span {
      margin: 0 10px;
      margin-left: 0;
    }
  }
}
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  visibility: visible;
  .left-wrap {
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 5px solid #757e93;
    .center-line {
      width: 100%;
      height: 15px;
      background-color: #f7f7f7;
      position: absolute;
      bottom: 0;
      user-select: none;
      line-height: 15px;
      cursor: pointer;
      text-align: center;
      .iconfont {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .top-wrap {
      width: 100%;
      height: 50%;
      position: relative;
      background-color: #fff;
      .tree-item {
        .iconfont {
          color: blue;
        }
        & span:nth-child(3) {
          color: grey;
        }
      }
      .title-wrap {
        padding: 8px;
        background-color: #f2f6f7;
        .box {
          display: flex;
          align-items: center;
          i {
            color: grey;
          }
          .line-col {
            display: inline-block;
            width: 1px;
            height: 35px;
            margin: 0 10px;
            background-color: #c2cdd6;
          }
          .title {
            font-weight: bold;
          }
        }
      }
    }
    .bottom-wrap {
      width: 100%;
      flex: 1;
      // background-color: blue;
      overflow-y: auto;
      z-index: 99;
      border-top: 5px solid #757e93;
      .title-wrap {
        background-color: #f2f6f7;
        border-bottom: 1px solid #a9b5c4;
        span {
          display: inline-block;
          padding: 10px;
          margin-right: 10px;
          margin-top: 3px;
          font-weight: bold;
        }
        .span-active {
          border: 1px solid #a9b5c4;
          border-bottom: none;
          border-radius: 3px;
          color: #3d4f98;
        }
      }
    }
  }
  .right-wrap {
    flex: 1;
    .setting-wrap {
      position: absolute;
      z-index: 99;
      right: 0;
      top: 200px;
      display: flex;
      .icon-wrap {
        .icon-weilan {
          display: inline-block;
          font-size: 30px;
          color: #fff;
          width: 45px;
          height: 45px;
          text-align: center;
          background-color: #0e932e;
          border-radius: 50%;
          margin-left: 15px;
        }
      }
      .rail {
        .rail-set {
          width: 450px;
          background-color: #f0f4f9;
          .my-rail {
            color: #e98f36;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 15px;
            .icon-delete {
              font-size: 22px;
            }
          }
          h4 {
            font-weight: bold;
            background-color: #fff;
            padding: 5px 10px;
          }
          .title {
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            margin-bottom: 20px;
            span {
              font-size: 17px;
              font-weight: bold;
              width: 50%;
              padding: 5px;
              background-color: grey;
            }
            .span-active {
              background-color: #f0f4f9;
            }
          }
          .btn-wrap {
            display: flex;
            justify-content: space-around;
            padding: 15px;
            .info-btn {
              background-color: rgb(1, 138, 119);
              color: #fff;
            }
          }
        }
      }
    }
    .reload-box {
      position: absolute;
      z-index: 99;
      left: 80px;
      top: 60px;
      span {
        font-weight: bold;
        color: red;
        padding-right: 5px;
      }
    }
    .show-left-wrap {
      position: absolute;
      left: -2px;
      top: 50%;
      width: 20px;
      height: 60px;
      margin-top: -30px;
      background-color: #ededed;
      z-index: 99;
      text-align: center;
      line-height: 60px;
    }
    .battery-address-info {
      width: 100%;
      background-color: #f2f6f7;
      padding: 10px;
      position: absolute;
      z-index: 99;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info-left {
        font-weight: bolder;
        font-size: 14px;
        width: 560px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .info-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .speed-place {
      position: absolute;
      left: 170px;
      top: 60px;
      z-index: 99;
      .speed {
        display: flex;
        width: 280px;
        align-items: center;
        background-color: #fff;
        justify-content: space-around;
        padding: 8px;
        border-radius: 3px;
        border: 1px solid #8ba4dc;
        margin-bottom: 15px;
        .speed-item {
          display: flex;
          align-items: center;
          .s-block {
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-left: 3px;
            border: 1px solid gray;
          }
          &::after {
            display: inline-block;
            content: '';
            width: 1px;
            height: 20px;
            margin-left: 8px;
            background-color: gray;
          }
          &:last-child::after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
